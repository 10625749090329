@import url('https://fonts.cdnfonts.com/css/euclid-circular-a');

* {
     margin: 0;
     padding: 0;
     font-family: Euclid Circular A !important;
}


hr {
     border: 0.5px solid gainsboro !important
}

.downloadBtm {
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     margin-top: 1%;

}

.downloadBtm>p {

     margin-bottom: 3%;
     font-size: 14px;
     font-style: normal;
     text-decoration: none;
     font-family: "Euclid Circular A";

}

.downloadBtm>a {

     margin-bottom: 3%;
     font-size: 14px;
     font-style: normal;
     text-decoration: none;
     color: royalblue;
     font-family: "Euclid Circular A";

}

.infoText {
     font-size: 10px !important;
     color: #929292
}

@import url("https://fonts.cdnfonts.com/css/euclid-circular-a?styles=100037,100032,100034,100033,100036,100035,100039,100038,100031,100030");

.heala-root-form-container form {
     margin: 0;
     width: 97% !important;
}

select {
     width: 100% !important;
     height: 45px;
     padding-left: 11px;
     border-radius: 5px;
     border: 1px solid rgba(128, 128, 128, 0.25);
     background: #fff;
     margin-left: 0%;
     font-size: 12px;
     color: #6c6c6c;
     font-weight: 300;
}

.heala-root-form-container textarea {
     width: 100% !important;
     /* padding-left: 11px; */
     padding-top: 11px;
     border-radius: 5px;
     border: solid 1px #cbc9c9;
     /* box-shadow: 1px 2px 5px rgb(0 0 0 / 9%); */
     background: #fff;
     margin-left: 0%;
     margin-bottom: 10px;
     font-size: 13px;
     height: 80px;
}


.heala-root-form-container button {
     width: 100% !important;
     padding: 10px 0;
     margin: 10px auto;
     border-radius: 5px;
     border: none;
     background: #3e5ea9;
     font-size: 14px;
     font-weight: 500;
     color: #fff;
}

.image-div {
     width: fit-content;
     height: 50px;
     border-radius: 50%;
     display: flex;
}

.image-div>img {
     width: 50px;
     height: 50px;
     border-radius: 50%;
}

/* html,
body {
    display: flex;
    justify-content: center;
    height: 100%;
} */
.heala-root-form-container {
     position: relative;
     width: 100%;
     height: 100vh;
     display: flex;
     align-items: center;
     justify-content: center;
}

.heala-root-form-container div,
.heala-root-form-container h3,
.heala-root-form-container form,
.heala-root-form-container input,
.heala-root-form-container p {
     padding: 0;
     margin: 0;
     outline: none;
     font-family: "Euclid Circular A";
     font-size: 16px;
     color: #666;
}

.heala-root-form-container h3 {
     font-family: "Euclid Circular A";
     font-style: normal;
     font-weight: 400;
     font-size: 24px;
     line-height: 30px;
     display: flex;
     align-items: center;
     color: #2c2c2c !important;
     width: 100%;
     justify-content: center;
     margin: 16px 0;
}

.heala-root-form-container p {
     font-size: 12px;
}

.heala-root-form-container hr {
     color: #a9a9a9;
     opacity: 0.3;
}

.heala-root-form-container .main-block {
     width: calc(500px - 48px);
     padding: 24px;
     margin: auto;
     background: #fbfbfb;
     border: 1px solid rgba(128, 128, 128, 0.25);
     border-radius: 16px;
     height: auto;
     overflow: hidden;
     max-height: calc(100vh - 80px);
}

.heala-root-form-container form {
     margin: 0;
     width: 97% !important;
}

.heala-root-form-container .account-type,
.heala-root-form-container .gender {
     margin: 15px 0;
}

.heala-root-form-container label#icon {
     margin: 0;
     border-radius: 5px 0 0 5px;
}

.heala-root-form-container label.radio {
     position: relative;
     display: inline-block;
     padding-top: 4px;
     margin-right: 20px;
     text-indent: 30px;
     overflow: visible;
     cursor: pointer;
}


.heala-root-form-container label.radio:before {
     content: "";
     position: absolute;
     top: 2px;
     left: 0;
     width: 20px;
     height: 20px;
     border-radius: 50%;
     background: #3e5ea9;
}

.heala-root-form-container label.radio:after {
     content: "";
     position: absolute;
     width: 9px;
     height: 4px;
     top: 8px;
     left: 4px;
     border: 3px solid #fff;
     border-top: none;
     border-right: none;
     transform: rotate(-45deg);
     opacity: 0;
}

.heala-root-form-container input[type="radio"]:checked+label:after {
     opacity: 1;
}

.heala-root-form-container input[type="email"],
.heala-root-form-container input[type="tel"],
.heala-root-form-container input[type="text"] {
     width: 100%;
     height: 45px;
     padding-left: 11px;
     border-radius: 5px;
     border: 1px solid rgba(128, 128, 128, 0.25);
     background: #fff;
     margin-left: 0%;
     font-size: 12px;
     color: #6c6c6c;
     font-weight: 300;
}

select {
     width: 100%;
     height: 45px;
     padding-left: 11px;
     border-radius: 5px;
     border: 1px solid rgba(128, 128, 128, 0.25);
     background: #fff;
     margin-left: 0%;
     font-size: 12px;
     color: #6c6c6c;
     font-weight: 300;
}

.heala-root-form-container textarea {
     width: 100%;
     /* padding-left: 11px; */
     padding-top: 11px;
     border-radius: 5px;
     border: solid 1px #cbc9c9;
     /* box-shadow: 1px 2px 5px rgb(0 0 0 / 9%); */
     background: #fff;
     margin-left: 0%;
     margin-bottom: 10px;
     font-size: 12px;
     height: 80px;
     font-family: "Euclid Circular A";

     padding: 2%;

}

.heala-root-form-container #icon {
     display: inline-block;
     padding: 9.3px 15px;
     box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.09);
     background: #3e5ea9;
     color: #fff;
     text-align: center;
}

.heala-root-form-container .btn-block {
     margin-top: 30px;
     text-align: center;
}

.heala-root-form-container button {
     width: 100%;
     padding: 10px 0;
     margin: 10px auto;
     border-radius: 5px;
     border: none;
     background: #3e5ea9;
     font-size: 14px;
     font-weight: 500;
     color: #fff;
}

.heala-root-form-container .iframe-close {
     border-radius: 50%;
     position: absolute;
     top: 0;
     right: 0;
     /* padding: 1.4% 2.1% 2%; */
     cursor: pointer;
     color: white;
     z-index: 200;
     background: #3d5da9;
     width: 35px;
     height: 35px;
     margin: 2%;
     display: flex;
     justify-content: center;
     align-items: center;

}

.heala-root-form-container .iframe-close1 {
     border-radius: 50%;
     position: absolute;
     top: 0;
     right: 0;
     /* padding: 1.4% 2.1% 2%; */
     cursor: pointer;
     color: white;
     z-index: 200;
     width: 35px;
     height: 35px;
     margin: 2%;
     display: flex;
     justify-content: center;
     align-items: center;
}


.heala-root-form-container button:hover {
     background: #3e5ea9;
}

.heala-root-form-container .heala-login-tab {
     width: 340px;
     padding: 10px;
     margin: auto;
     border-radius: 5px;
     border: solid 1px #ccc;
     box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.31);
     background: #ebebeb;
     position: absolute;
     bottom: 100%;
     right: 100%;
     margin-right: 16px;
     margin-bottom: 16px;
     display: none;
     background: #fbfbfb;
     border: 1.11698px solid #eaeaea;
     border-radius: 9.57px;
}

.heala-iframe-container {
     position: absolute;
     width: 100%;
     height: 100%;
     background-color: rgba(21, 20, 20, 0.119);
     z-index: 100;
     top: 0px;
     align-items: center;
     justify-content: center;
     display: flex;
     background: #f6f7fb;
     border: 1.11698px solid #eaeaea;
}

.heala-iframe-container .heala-iframe-content {
     width: 100%;
     height: 100%;
     background-color: white;
     background: #fbfbfb;

     background-color: #f6f7fb;
}

.heala-iframe-container .heala-iframe-content iframe {
     width: 100%;
     height: 100%;
     border: none;
}

.heala-iframe-container .iframe-close {
     border-radius: 50%;
     position: absolute;
     top: 0;
     right: 0;
     /* padding: 1.4% 2.1% 2%; */
     cursor: pointer;
     color: white;
     z-index: 200;
     background: #3d5da9;
     width: 35px;
     height: 35px;
     margin: 2%;
     display: flex;
     justify-content: center;
     align-items: center;
}

.heela-form-title-logo {
     max-width: 150px;
     max-height: 60px;
}

.heela-form-title-text {
     font-family: "Euclid Circular A";
     font-style: normal;
     font-weight: 300;
     font-size: 41.7969px !important;
     line-height: 53px;
     display: flex;
     align-items: center;
     color: #3e5ea9 !important;
}

.heela-form-title-container {
     display: flex;
     align-items: center;
     justify-content: center;
     gap: 4px;
     padding: 16px 0px !important;
     border-bottom: 1px solid #979797;
}

.heela-form-container {
     padding: 15px !important;
     border-radius: 24px;
     display: flex;
     overflow: hidden;


}

.heela-form-container {
     position: relative;

}

.heela-form-container::before {
     content: "";
     position: absolute;
     top: 0;
     bottom: 0;
     left: 0;
     right: 0;
     opacity: 0.2;
     background-color: red;


}

.heela-form-container>* {
     z-index: 1;
     opacity: 1;


}


.heela-form-description {
     font-family: "Euclid Circular A";
     font-style: normal;
     font-weight: 400;
     font-size: 12px !important;
     line-height: 20px;
     display: flex;
     align-items: center;
     text-align: center;
     color: #6c6c6c !important;
     justify-content: center;
}

.heela-form-controller {
     overflow: scroll;
     -ms-overflow-style: none;
     /* IE and Edge */
     scrollbar-width: none;
     height: calc(100vh - 280px);
}

.heela-form-controller h3 {
     font-family: "Euclid Circular A";
     font-style: normal;
     font-weight: 400;
     font-size: 24px;
     line-height: 30px;
     display: flex;
     align-items: center;
     color: #2c2c2c !important;
     width: 100%;
     justify-content: center;
     margin: 16px 0;
}

.heela-form-controller::-webkit-scrollbar {
     display: none;
}

.heela-form-symptoms {
     flex-direction: column;
     display: flex;
     align-items: center;
     justify-content: center;
     gap: 8px;
}

.heala-border {
     border: 1px solid rgba(128, 128, 128, 0.25);
}

.heala-doc-profile {
     padding: 35px 45px 30px 45px;
     display: flex;
     flex-direction: column;
     justify-content: center;

}

.heala-rounded {
     border-radius: 8px 0px 0px 8px;
     height: 513px;

}

.heala__doc--phone label {
     display: none;
}

.heela-form-symptoms .add-icon {
     height: 100%;
     margin-top: 10px;
     display: flex;
     align-items: center;
     justify-content: center;
     cursor: pointer;
}

.heela-batches {
     padding: 4px 8px !important;
     background-color: #96919152;
     display: flex;
     justify-content: center;
     gap: 4px;
     align-items: center;
}

.heela-form-top {
     display: flex;
     width: 100%;
}

.heela-symptoms-input {
     width: 100%;
     display: flex;
}

.heela-symptoms-container {
     background-color: #f1f1f1;
     padding: 1rem;
     display: flex;
     flex-direction: column;
     gap: 10px;
     cursor: pointer;
     border-radius: 5px;
     align-self: flex-start;
}

.heela-symptoms-option {
     transition: all .2s ease;
}

.heela-symptoms-option:hover {
     color: #a9a9a9;
}

.heela-symptoms-batches-container {
     display: flex;
     justify-content: flex-start;
     align-items: center;
     margin-top: 4px !important;
     gap: 4px;
     flex-wrap: wrap;
}

.heela-batches-cancel {
     background-color: #9e9999;
     padding: 4px;
     cursor: pointer;
     height: 20px;
     display: flex;
     justify-content: center;
     align-items: center;
}

.red {
     display: flex;
     justify-content: center;
     align-self: center;
     color: red;
     font-size: 1.2rem;

}

.redtext {
     display: flex;
     justify-content: left;
     align-self: flex-start;
     margin: 0%;
     color: red;
     font-size: 0.7rem;
     font-style: italic;
}

.green {
     display: flex;
     justify-content: center;
     align-self: center;
     color: green;
     font-size: 1.2rem;

}

.gray {
     display: flex;
     justify-content: center;
     align-self: center;
     color: gray;
     font-size: 1.2rem;
}

.step-btn button:first-child {

     padding: 9px 48px !important;

}

.heala-form-auth button:first-of-type {
     display: block;
}

.heala-form-auth button:last-of-type {
     display: none;
}

@media (max-width: 901px) {
     .heala-form-auth button:first-of-type {
          display: none !important;
     }

     .heala-form-auth button:last-of-type {
          display: block !important;
          text-transform: capitalize;
          font-size: 13px !important;
          font-family: "Euclid Circular A" !important;
          border-radius: 8px;
     }

     .heala-otp-form button {
          text-transform: capitalize;
          font-family: "Euclid Circular A" !important;
     }
}

.makeStyles-logo-3 {
     max-height: "50px" !important;
     max-width: "150px" !important;
}

.makeStyles-powered-11 {
     position: absolute !important;
     top: 93% !important;
}

@media (max-width: 599.95px) {
     .makeStyles-br-40 {
          display: block !important;
     }
}

@media screen and (max-width: 600px) {
     .makeStyles-br-10 {
          display: block !important;
     }

     .makeStyles-powered-11 {
          position: absolute !important;
          top: 90% !important;
     }

     .stepper__container--next {
          margin-bottom: 40px;
          margin-top: 5%;
     }

     .makeStyles-button1-24 {
          width: auto;
          height: auto;
          padding: 9px 60px !important;
          font-size: 18px !important;
          align-self: center;
          background: #3E5EA9 !important;
          margin-top: 55px !important;
          font-family: Euclid Circular A !important;
          font-weight: 500 !important;
          border-radius: 12px !important;
          text-transform: capitalize !important;
     }

     .redtext {
          display: flex;
          justify-content: left;
          align-self: flex-start;
          margin: 2% 0%;
          color: red;
          font-size: 0.6rem;
          font-style: italic;
     }

     .conBG {
          padding: 8px !important;
          border-radius: 0px !important;
     }

     .heala-root-form-container .main-block {
          width: auto;
          padding: 24px;
          margin: auto;
          background: #fbfbfb;
          border: 1px solid rgba(128, 128, 128, 0.25);
          border-radius: 16px;
          height: auto;
          overflow: hidden;
          max-height: calc(100vh - 80px);
     }

     .heela-form-container {
          width: 100vw;
          /* height: 100vh; */
          border-radius: 0px;
          padding: 20px !important;
          height: calc(100% - 40px);
          padding-bottom: 35px !important;
          padding-top: 40px !important;
     }

     .heela-form-controller {
          height: calc(100vh - 180px);
     }

}

.stepper {
     width: 100%;
     cursor: pointer;
}

.stepper__container svg {
     cursor: pointer;
}

.stepper__container {
     padding: 50px 0px 0px 0px;
     display: flex;
     flex-direction: column;
     width: 100%;
     gap: 10px;
     /* align-items: center; */
     /* overflow: hidden; */
}

.stepper__box {
     display: flex;
     gap: 40px;
     width: 100%;
     justify-content: center;
}

.stepper__input {
     display: flex;
     width: 100%;
     flex-direction: column;
     margin: 0.7rem 0rem;
}

.stepper__input label {
     margin-bottom: 8px;
     font-weight: 400;
     font-size: 14px;
     width: 100%;
     font-family: 'Euclid Circular A';
}

.stepper__input input {
     padding: 10px;
     height: 35px;
     border: 1px solid #979797;
     border-radius: 16px;
     font-weight: 400;
     font-size: 16px;
     color: #6c6c6c;
     font-family: 'Euclid Circular A';
}

.stepper__input select {
     padding: 10px;
     height: 55px;
     border: 1px solid #979797;
     border-radius: 16px;
     font-weight: 400;
     font-size: 16px;
     color: #6c6c6c;
     font-family: 'Euclid Circular A';
     box-sizing: border-box;
     margin: 0px;
}

.stepper__input input:focus {
     outline: 1.5px solid #979797;
}

.error__msg {
     font-size: 12px;
     margin: 0px 0px 0px 0.6rem;
     color: red;
}

.text-error {
     font-size: 10px;
     margin: 0px 0px 0px 0.3rem;
     color: red;
}

.swiper-wrapper {
     width: 500px !important;
     height: 100vh !important;
}

@media (max-width: 1200px) {
     .swiper-wrapper {
          width: 400px !important;
     }
}

.stepper__next--head {
     display: flex;
     font-weight: 500;
     font-size: 20px;
     font-family: 'Euclid Circular A';
     gap: 31px;
     align-items: center;
     margin-top: 2%;

}

/* .stepper__next--head input {
     padding: 10px;
     height: 35px;
     border: 1px solid #979797;
     border-radius: 16px;
     font-weight: 400;
     font-size: 16px;
     color: #6c6c6c;
     font-family: 'Euclid Circular A' ;
} */

.stepper__avail--head {
     display: flex;
     margin-bottom: 20px;
     font-weight: 500;
     font-size: 20px;
     color: #2c2c2c;
     font-family: 'Euclid Circular A';
     gap: 8px;
}

.stepper__time--head {
     margin-bottom: 45px;
     font-weight: 500;
     font-size: 20px;
     color: #2c2c2c;
     font-family: 'Euclid Circular A';
}

.stepper__avail--body {
     display: flex;
     gap: 13px;
}

.stepper__time--body {
     display: grid;
     grid-template-columns: repeat(4, 1fr);
     gap: 24px;
     width: 100%;
}

.stepper__container--next {
     margin-bottom: 74px;
}

.stepper__container--avail {
     margin-bottom: 40px;
     width: 100%;
}

.stepper__container--next {
     margin-bottom: 40px;
     margin-top: 5%;
}

.stepper__container--time {
     margin-bottom: 15px;
     width: 100%;
}

.stepper__form .label {
     font-family: 'Euclid Circular A';
}

.stepper__form .input {
     width: 100%;
     height: 56px;
     border: 1px solid #80808040;
     border-radius: 10px;
     padding: 0px 16px;
     font-size: 12px;
     font-weight: 400;
     font-family: 'Euclid Circular A';
     color: #6c6c6c;
}

.stepper__form .input:focus {
     outline: none;
}

.stepper__form .heela-form-top {
     margin-bottom: 16px;
}

.stepper__form select {
     margin: 0;
}

.add-icon {
     cursor: pointer;
     border: none;
     background-color: transparent;
}

.stepper__last {
     height: 100px;
     border: 1px solid rgba(128, 128, 128, 0.25);
     padding: 10px 20px;
     border-radius: 12px;
     display: flex;
     gap: 49px;
     align-items: center;
     width: auto;
     align-self: center;
     margin-bottom: 35px;
}

.stepper__last--down {
     height: auto;
     border: 1px solid rgba(128, 128, 128, 0.25);
     padding: 25px;
     border-radius: 12px;
     width: 100%;
     height: auto;
}

.stepper__last p {
     font-weight: 500;
     font-size: 20px;
     font-family: 'Euclid Circular A';
     color: #2c2c2c;
}

.stepper__last div {
     display: flex;
     border: 1px solid rgba(128, 128, 128, 0.25);
     border-radius: 8px;
     padding: 24px 24px;
     flex: 1 1;
     justify-content: center;
     gap: 10px;
     align-items: center;
}

.stepper__last div p {
     font-weight: 400;
     font-size: 19px;
}

.stepper__down--head {
     display: flex;
     justify-content: space-between;
     width: 100%;
     margin-bottom: 15px;
}

.stepper__down--head p {
     font-weight: 500;
     font-size: 20px;
     font-family: 'Euclid Circular A';
}

.stepper__down--title {
     font-weight: 500;
     font-size: 14px;
     font-family: 'Euclid Circular A';
     margin-bottom: 5px;
}

.stepper__down--divider {
     display: flex;
     justify-content: space-between;
     gap: 150px;
}

.stepper__down--divider p {
     font-weight: 500;
     font-size: 12px;
     color: #6c6c6c;
     font-family: 'Euclid Circular A';
}

.stepper__down-text {
     gap: 8px;
     display: flex;
     flex-direction: column;
}

.stepper__down--mids {
     margin-top: 0px;
}

.stepper__down--mids .stepper__down--divider {
     margin-bottom: 0px;
}

.stepper__diff--btn {
     width: 100%;
     display: flex;
     gap: 24px;
     justify-content: center;
}

.stepper__down--mids .stepper__diff--btn {
     width: 80%;
     display: flex;
     gap: 24px;
     justify-content: center;
     align-self: center;
}

.step-btn button:first-child {
     background: transparent !important;
}

.stepper__logo {
     height: 100%;
}

.visa {
     width: 15%;

}


.swiper__details>img {
     width: 35%;
}

.swiper-box {
     padding: 8px 16px;
     width: 90% !important;
     height: 120px;
     border: 1px solid #80808040;
     border-radius: 12px;
     display: flex;
     align-items: center;
     cursor: pointer;
     color: #2c2c2c;
     transition: all .2s ease;
}

.swiper-box-active {
     background-color: #3e5ea9;
     color: #fff;
}

.swiper-box:hover {
     background-color: #3e5ea9;
     color: #fff;
}

.swiper__details {
     display: flex;
     gap: 13px;
}

.swiper-slide.stepper--swiper {
     margin-right: 5px !important;
}

.swiper__text {
     display: flex;
     flex-direction: column;
     align-self: center;
}

.swiper__text p:first-child {
     font-weight: 500;
     font-size: 18px;
     font-family: 'Euclid Circular A';
}

.swiper__text p:last-child {
     font-weight: 400;
     font-size: 16px;
     font-family: 'Euclid Circular A';
}

.stepper--swiper {
     width: 310px !important;
}

.stepper__form .heela-form-top {
     margin-bottom: 0px;
     display: flex;
     flex-direction: column;
}

.time__slot {
     display: flex;
     justify-content: center;
     align-items: center;
     border-radius: 12px;
     width: 150px;
     height: 50px;
     cursor: pointer;
     font-weight: 500;
     font-size: 18px;
     font-family: 'Euclid Circular A';
     border: none;
}

.time__slot[disabled]:hover {
     background-color: #55555540;
     opacity: 0.5;
     cursor: not-allowed;
}

.time__slot:hover {
     background-color: rgba(146, 248, 201, 0.4);
     color: rgba(108, 108, 108, 1);
}

.aval__time {
     background-color: rgba(146, 248, 201, 0.2);
     color: rgba(108, 108, 108, 1);
}

.selected__time,
.selected__time:hover {
     background-color: #3E5EA9;
     color: #ffffff;
}



.disabled__time {
     background-color: rgba(128, 128, 128, 0.25);
     color: rgba(108, 108, 108, 1);
}


@media screen and (max-width: 900px) {
     .heala-doc-profile {

          padding: 16px;
          display: flex;
          flex-direction: column;
          justify-content: center;
     }

     .heala-rounded {
          border-radius: 8px 8px 0px 0px;
          height: inherit;

     }

     .makeStyles-content-36 {
          width: 95%;
          margin: 20px 0px;
          /* display: flex; */
          align-items: flex-start;
          display: flex;
          align-self: center;
          justify-content: center;
     }

     .makeStyles-content-13 {
          width: 95%;
          margin: 20px 0px;
          display: flex !important;
          justify-content: center !important;

          align-self: center !important;
     }

     .visa {
          width: 30%;
          height: 30%;

     }

     .step-btn button:first-child {
          background: transparent !important;
          padding: 9px 42px !important;

     }

     .stepper__down--head p {
          font-weight: 500;
          font-size: 17px;
          font-family: 'Euclid Circular A';
          width: 59%;

     }

     .stepper__last--down {
          height: auto;
          border: 1px solid rgba(128, 128, 128, 0.25);
          padding: 15px;
          border-radius: 12px;
          height: auto;
     }

     .stepper__down-text {
          gap: 8px;
          display: block;
          flex-direction: column;
     }

     .stepper__last {
          height: auto;
          gap: 15px;
          border: 1px solid rgba(128, 128, 128, 0.25) !important;
          padding: 10px 20px;
          border-radius: 12px;
          display: flex;
          flex-direction: column;
          align-items: center;
          width: auto;
          align-self: center;
          margin-bottom: 10px;
          margin-top: -26px;
          border: navajowhite;
     }

     .stepper__last div p {
          font-weight: 400;
          font-size: 18px;
          white-space: nowrap;

     }

     .stepper__last p {
          font-weight: 500;
          font-size: 16px;
          font-family: 'Euclid Circular A';
          color: #2c2c2c;
     }

     .makeStyles-footer-22 {
          padding: 28px 25px !important;
     }

     .makeStyles-foot-112 {
          padding: 10px 30px;
     }

     .makeStyles-button-23 {
          width: auto;
          height: auto;
          margin-top: 25px !important;
     }

     .stepper__box {
          display: flex;
          gap: 25px;
          width: 100%;
          justify-content: center;
          flex-direction: column;
          margin-bottom: 5%;
     }

     .stepper__container {
          padding: 5px 0px 0px 0px;
          display: flex;
          flex-direction: column;
          width: 100%;
          gap: 10px;
          width: 100%;
     }

     .stepper {
          display: none;
     }

     .stepper__input label {
          font-size: 15px;
     }

     .stepper__input input {
          height: 35px;
          border-radius: 10px;
     }

     .stepper__next--head {
          font-size: 15px;
          margin-bottom: 0px;
     }
}



@media screen and (max-width: 600px) {
     .stepper__down--divider {
          display: block;
     }

     .stepper__down--divider>p {
          font-weight: bold;
     }

     .stepper__avail--head {
          display: flex;
          margin-bottom: 33px;
          font-weight: 500;
          font-size: 15px;
          color: #2c2c2c;
          font-family: 'Euclid Circular A';
          gap: 8px;
     }

     .swiper-box {
          padding: 8px 16px;
          width: 100% !important;
          border: 1px solid #80808040;
          border-radius: 12px;
          display: flex;
          align-items: center;
          cursor: pointer;
          transition: all .2s ease;
     }

     .stepper__time--head {
          margin-bottom: 45px;
          font-weight: 500;
          font-size: 15px;
          color: #2c2c2c;
          font-family: 'Euclid Circular A';
     }

     .stepper__time--body {
          display: flex;
          /* grid-template-columns: repeat(4, 1fr); */
          gap: 24px;
          width: 100%;
          flex-wrap: wrap;
          justify-content: center;

     }

     .stepper__form .label {
          font-family: 'Euclid Circular A';
     }

     .stepper__next--head {
          display: flex;
          font-weight: 500;
          font-size: 15px;
          font-family: 'Euclid Circular A';
          gap: 10px;
          align-items: center;
          margin-top: -11%;
     }

     .time__slot {
          padding: 16px;
          border-radius: 12px;
          cursor: pointer;
     }

     .time__slot p {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          font-weight: 500;
          font-size: 14px;
          color: #6c6c6c;
          font-family: 'Euclid Circular A';
     }

     .swiper__text p:first-child {
          font-weight: 500;
          font-size: 14px;
          font-family: 'Euclid Circular A';
     }

     .swiper__text p:last-child {
          font-weight: 400;
          font-size: 12px;
          font-family: 'Euclid Circular A';
     }

     .stepper--swiper {
          width: 100% !important;
     }

     .swiper-slide.stepper--swiper {
          margin-right: 0px !important;
     }

     .stepper__container--next {
          margin-bottom: 0px;
          margin-top: 5%;
     }

     .stepper__time--body {
          display: flex;
          /* grid-template-columns: repeat(4, 1fr); */
          gap: 13px;
          width: 100%;
          flex-wrap: wrap;
          justify-content: center;
     }

     .stepper__container--time {
          width: 100%;
     }

     .stepper__time--head {
          margin-bottom: 25px;
          font-weight: 500;
          font-size: 15px;
          color: #2c2c2c;
          font-family: 'Euclid Circular A';
     }
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
     content: 'next';
     font-size: 0.8rem !important;
     font-weight: 900;
     color: white;
}

.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
     content: 'prev';
     font-size: 0.8rem !important;
     font-weight: 900;
     color: white;

}

.swiper-button-next,
.swiper-button-prev {
     position: absolute;
     top: var(--swiper-navigation-top-offset, 50%);
     width: calc(var(--swiper-navigation-size)/ 44 * 27);
     height: var(--swiper-navigation-size);
     margin-top: calc(0px - (var(--swiper-navigation-size)/ 2));
     z-index: 10;
     cursor: pointer;
     display: flex;
     align-items: center;
     justify-content: center;
     color: var(--swiper-navigation-color, var(--swiper-theme-color));
     background: #3d5da9;
     border-radius: 50%;
     width: 40px !important;
     height: 40px !important;
}

select {
     background-image: url('https://cdn-icons-png.flaticon.com/512/152/152415.png');
     background-position: calc(100% - 10px) center;
     background-size: 10px;
     background-repeat: no-repeat;


     -webkit-appearance: none;
     -moz-appearance: none;
     appearance: none;
     padding-right: 25px;
}

.MuiFormControl-root {
     width: 100%;
}

.MuiInputBase-root {
     padding: 10px 24px 14px 24px !important;
     cursor: pointer;
     border-radius: 8px !important;
     font-family: inherit !important;
     transition: all .2s ease-out;
}

.MuiInputBase-root::before {
     border: 0 !important;
}

.MuiInputBase-input {
     padding: 0 !important;
     letter-spacing: -0.2px !important;
}

.MuiInput-input {
     padding: 0px 0px 0px 0px !important;
}

.MuiOutlinedInput-notchedOutline:not(.Mui-error fieldset) {
     border-width: 1px !important;
}

.MuiOutlinedInput-root:hover fieldset {
     border-color: #EDEDED !important;
}

.MuiInputLabel-root.Mui-error {
     color: #00000099 !important;
}

.MuiIconButton-root:hover {
     background-color: transparent !important;
}

.MuiIconButton-root::after {
     content: url(../src//assets/svgs/dropdown.svg);
}

.MuiIconButton-sizeSmall::after {
     content: '' !important;
}

@media (min-width:554px) {
     /* .MuiPickersPopper-root {
          width: 512px;     
     } */
     
     .MuiDateCalendar-root {
          width: 100% !important;
     }
     
     /* .MuiDayCalendar-header {
          margin: 0rem 4.5rem !important;
     } */
}

.MuiPickersDay-root .selected {
     background-color: #3e5ea9 !important;
}

.MuiDayCalendar-header {
     margin: 0rem 0.4rem;
     justify-content: space-between !important;
}

.MuiPickersCalendarHeader-root {
     padding-left: 12px !important;
}

.MuiDayCalendar-weekContainer {
     width: 100%;
     justify-content: space-between !important;
}

.MuiPickersDay-root {
     font-size: 0.8rem !important;
}

.MuiPickersCalendarHeader-label {
     color: #3e5ea9;
}

.MuiIconButton-root svg:first-of-type {
     display: none;
}

@media (max-width:600px) {
     .MuiDrawer-paper {
          width: inherit;
     }
}

.MuiDrawer-paper {
     font-family: "Euclid Circular A" !important;
}

.leftbox-clicked {
     color: #3e5ea9;
     background-color: #F1F5FC;
     border-radius: 8px;
}

.leftbox-clicked svg:first-child {
     display: none;
}

.leftbox-svg {
     display: none;
}

.leftbox-clicked .leftbox-svg {
     display: block;
}

.custom-fieldset fieldset {
     height: 120px;
}

.custom-input input {
     height: 120px;
}

.Mui-disabled {
     color: #0C1322 !important;
     -webkit-text-fill-color: #0C1322 !important;
}

.leftbar {
     height: calc(100vh - 40px);
     width: 196px;
     /* calc(100% - 86.2%); */
}

.MuiChip-icon {
     margin-right: 12px !important;
}

.filter-chip .MuiChip-label {
     padding-right: 5px !important;
}

@media (max-width:900px) {
     .MuiTable-root {
          width: 1118px !important;
     }
}

.table-img img {
     height: 40px;
     width: 40px;
     border-radius: 50%;
     object-fit: cover;
}

.table-image img {
     height: 50px;
     width: 50px;
     border-radius: 50%;
     object-fit: cover;
}

.react-tel-input .form-control :hover {
     border: 1px solid #777 !important;
}

.react-tel-input .form-control {
     width: 100% !important;
     border: 1px solid #EDEDED !important;
     border-radius: 8px !important;
     padding: 12px 55px !important;
}

.react-tel-input .flag-dropdown {
     background: #EEEEEE !important;
     border-radius: 8px 0 0 8px !important;
}

.react-tel-input .form-control:focus {
     box-shadow: none !important;
}

.react-tel-input .selected-flag.open:before {
     box-shadow: none !important;
     border-color: inherit !important;
}

.react-tel-input ::placeholder {
     color: #CED0D3 !important;
     font-family: "Euclid Circular A" !important;
}

.react-tel-input .country-list {
     font-family: "Euclid Circular A" !important;
}

.consult-success svg {
     fill: #777;
}

.doctor-form .phone {
     width: 100%;
}

.symptom-add {
     /* padding: 11px !important; */
     color: #ffffff !important;
     align-items: center;
     height: 4vh;
     width: 10%;
     text-align: center;
     
     justify-content: center;
     margin-top: 4% !important;
}

#myID {
     width: 100%;
     height: 45px;
     padding-left: 11px;
     border-radius: 5px;
     border: 1px solid rgba(128, 128, 128, 0.25);
     background: #fff;
     margin-left: 0%;
     font-size: 12px;
     color: black;
     font-weight: 300;
}


.items-center_fix {
     align-items: center;
     margin-top: 4%;
}




/* common */
.ribbon {
     width: 150px;
     height: 150px;
     overflow: hidden;
     position: absolute;
}

.ribbon::before,
.ribbon::after {
     position: absolute;
     z-index: -1;
     content: '';
     display: block;
     border: 5px solid #2980b9;
}

.ribbon div {
     position: absolute;
     display: block;
     width: 225px;
     padding: 15px 0;
     background-color: #3498db;
     box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
     color: #fff;
     font: 700 18px/1 'Lato', sans-serif;
     text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
     text-transform: uppercase;
     text-align: center;
}

/* top left*/
.ribbon-top-left {
     top: -10px;
     left: -10px;
}

.ribbon-top-left::before,
.ribbon-top-left::after {
     border-top-color: transparent;
     border-left-color: transparent;
}

.ribbon-top-left::before {
     top: 0;
     right: 0;
}

.ribbon-top-left::after {
     bottom: 0;
     left: 0;
}

.ribbon-top-left div {
     right: -25px;
     top: 30px;
     transform: rotate(-45deg);
}

/* top right*/
.ribbon-top-right {
     top: -10px;
     right: -10px;
}

.ribbon-top-right::before,
.ribbon-top-right::after {
     border-top-color: transparent;
     border-right-color: transparent;
}

.ribbon-top-right::before {
     top: 0;
     left: 0;
}

.ribbon-top-right::after {
     bottom: 0;
     right: 0;
}

.ribbon-top-right div {
     left: -25px;
     top: 30px;
     transform: rotate(45deg);
}

/* bottom left*/
.ribbon-bottom-left {
     bottom: -10px;
     left: -10px;
}

.ribbon-bottom-left::before,
.ribbon-bottom-left::after {
     border-bottom-color: transparent;
     border-left-color: transparent;
}

.ribbon-bottom-left::before {
     bottom: 0;
     right: 0;
}

.ribbon-bottom-left::after {
     top: 0;
     left: 0;
}

.ribbon-bottom-left div {
     right: -25px;
     bottom: 30px;
     transform: rotate(225deg);
}

/* bottom right*/
.ribbon-bottom-right {
     bottom: -10px;
     right: -10px;
}

.ribbon-bottom-right::before,
.ribbon-bottom-right::after {
     border-bottom-color: transparent;
     border-right-color: transparent;
}

.ribbon-bottom-right::before {
     bottom: 0;
     left: 0;
}

.ribbon-bottom-right::after {
     top: 0;
     right: 0;
}

.ribbon-bottom-right div {
     left: -25px;
     bottom: 30px;
     transform: rotate(-225deg);
}


.ribbon-top-right {
     top: 8px;
     right: -4px;
}

.ribbon {
     width: 138px;
     height: 150px;
     overflow: hidden;
     position: absolute;
}

.ribbon-top-right div {
     left: -4px;
     top: 27px;
     transform: rotate(45deg);
}

.ribbon {
     width: 138px;
     height: 150px;
     overflow: hidden;
     position: absolute;
}

.ribbon {
     width: 178px;
     height: 163px;
     overflow: hidden;
     position: absolute;
}

.ribbon-top-right div {
     left: -4px;
     top: 39px;
     transform: rotate(45deg);
}

.ribbon div {
     position: absolute;
     display: block;
     width: 265px;
     padding: 15px 0;
     background-color: #3498db;
     box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
     color: #fff;
     font: 700 18px/1 'Lato', sans-serif;
     text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
     text-transform: uppercase;
     text-align: center;
}

.ribbon {
     width: 201px;
     height: 225px;
     overflow: hidden;
     position: absolute;
}

.ribbon div {
     position: absolute;
     display: block;
     width: 265px;
     padding: 15px 0;
     background-color: #3498db;
     box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
     color: #fff;
     font: 700 18px/1 'Lato', sans-serif;
     text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
     text-transform: uppercase;
     text-align: center;
     display: flex;
     justify-content: center;
     flex-direction: column;
}

.img_fix {
     text-align: center;
     width: 39%;
     margin-left: 28%;
     margin-top: 2%;
     margin-bottom: -2%;
}

.ribbon-top-right div {
     left: -4px;
     top: 34px;
     transform: rotate(45deg);
}

.text_fix {
     font-size: 11px;
     text-transform: capitalize;
     margin-top: -3%;
     font-weight: 500;
}

.ribbon-top-right div {
     left: 14px;
     top: 34px;
     transform: rotate(45deg);
}

.ribbon div {
     position: absolute;
     display: block;
     width: 265px;
     padding: 15px 0;
     background-color: #40474b;
     box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
     color: #fff;
     font: 700 18px/1 'Lato', sans-serif;
     text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
     text-transform: uppercase;
     text-align: center;
     display: flex;
     justify-content: center;
     flex-direction: column;
}

.mt-6.flex.flex-col.items-center.w-full.min-\[520px\]\:w-\[80\%\] {
     width: 95% !important;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPickersPopper-paper.css-1k4oq0i-MuiPaper-root-MuiPickersPopper-paper {
     width: 184.3% !important;
     padding: 5% !important;
     margin-top: 2% !important;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPickersPopper-paper.css-a9rw36{
     width: 184.3% !important;
     padding: 5% !important;
     margin-top: 2% !important;

}

@media (max-width:900px) {
     .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPickersPopper-paper.css-a9rw36{
          width: 105% !important;
          padding: 2% !important;
          justify-content: center;
          margin-left: -0.5rem;

     }

     .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPickersPopper-paper.css-1k4oq0i-MuiPaper-root-MuiPickersPopper-paper {
          width: 105% !important;
          padding: 2% !important;
          justify-content: center;
          margin-left: -0.5rem;

     }

     .consult-success svg {
          fill: #777;
          width: 11%;
     }

     .conmainsult-success {
          margin-left: 4px !important;

     }

     .btn_flex {
          display: flex;
          flex-direction: column-reverse;
     }

     .ribbon div {
          position: absolute;
          display: block;
          width: 242px;
          padding: 15px 0;
          background-color: #40474b;
          box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
          color: #fff;
          font: 700 18px/1 'Lato', sans-serif;
          text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
          text-transform: uppercase;
          text-align: center;
          display: flex;
          justify-content: center;
          flex-direction: column;
     }

     .img_fix {
          text-align: center;
          width: 28%;
          margin-left: 28%;
          margin-top: 0%;
          margin-bottom: -4%;
     }

     .text_fix {
          font-size: 9px;
          text-transform: capitalize;
          margin-top: -4%;
          font-weight: 500;
     }

     .ribbon-top-right div {
          left: 46px;
          top: 19px;
          transform: rotate(45deg);
     }

     .img_fix {
          text-align: center;
          width: 28%;
          margin-left: 34%;
          margin-top: 0%;
          margin-bottom: -4%;
     }

}

p.font-normal.text-\[13px\] {
     line-height: 2;
}

.text-gray-400 {
     --tw-text-opacity: 1;
     color: rgb(12 19 34 / var(--tw-text-opacity));
     font-size: 13px;
}


.w-2\/5 {
     width: 80%;
}

.bg-secondary {
     overflow-x: hidden !important;
}

.MuiPickersCalendarHeader-root {
     padding: 0% !important;
     margin-top: 0 !important;
}

.css-1cafy48-MuiPickersSlideTransition-root-MuiDayCalendar-slideTransition {
     min-height: 215px !important;
}

.makeStyles-PTag-2 {
     text-align: left;
     font-size: 14px;
}

.heala-info-error .error__msg {
     font-size: 10px;
     margin: -2px 0px 0px 0.3rem;
}

.chip-input-container {
     display: flex;
     flex-direction: column;
}

.chips-input {
     display: flex;
     flex-wrap: wrap;
     gap: 4px;
     align-items: flex-start;
     padding: 8px 10px 6px 11px;
     border: 1px solid #EDEDED;
     border-radius: 8px;
     min-height: 1px;
     /* height: 30px; */
     max-width: 100%;
}

.chips-input .MuiTextField-root {
     border: none;
     flex: 1;
}

.chips-input .MuiTextField-root:focus {
     outline: none;
}

.chips-input .MuiInputBase-root {
     padding: 5px 10px 6px 0px !important;
}

.mid\:py-\[19px\] {
     padding-top: 19px;
     padding-bottom: 19px;
     justify-content: center;
}

.current-page {
     background: #3e5ea9;
     color: #fff;
     align-items: center;
     border-radius: 8px;
     width: 32px;
     height: 30px;
     display: flex;
     justify-content: center;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPickersPopper-paper.css-1yllih9-MuiPaper-root-MuiPickersPopper-paper {
     width: 184.3% !important;
     padding: 5% !important;
     margin-top: 2% !important;
 }